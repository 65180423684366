.pinput-container {
  display: flex;
  justify-content: space-around;
  padding: 32px 12px;

  input {
    width: 10%;
    font-size: 48px;
    font-weight: bold;
    text-align: center;
    outline: none;
    border: none;
    border-bottom: 6px solid #534e84;
    background: transparent;

    &:focus {
      border-color: #0000ff;
    }
  }
}
