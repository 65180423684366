#dashboard-page {
  > header {
    margin: 0 42px;
    padding: 42px 0;
    border-bottom: 1px solid #efefef;

    h1 {
      font-size: 26px;
      font-weight: bold;
      margin: 12px 0;
    }

    p {
      color: #534e84;
      font-size: 24px;
      font-weight: 100;
      margin: 12px 0;
    }
  }

  ul.user-study-list {
    list-style-type: none;
    margin: 42px;
    padding: 0;

    li.user-study-item {
      background: #fff;
      border-radius: 12px;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
      padding: 12px 24px;
      cursor: pointer;
      border: 4px solid transparent;
      transition: border 150ms;
      margin-bottom: 24px;

      > header {
        border-bottom: 1px solid #efefef;

        h4 {
          margin: 0 0 8px 0;
          color: #534e84;
        }
      }

      > section {
        margin: 12px 0;
        display: flex;
        align-items: center;

        .study-details {
          flex-grow: 1;

          h3 {
            margin: 0 0 8px 0;
          }

          p {
            font-size: 12px;
            margin: 0;
            max-width: 60%;
            line-height: normal;
            color: #527f8e;
          }
        }

        .action-text {
          display: flex;
          white-space: nowrap;
        }
      }

      &:hover {
        border: 4px solid #534e84;
      }
    }
  }

  .no-studies-message {
    margin: 42px;
  }
}
