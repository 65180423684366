.checkbox-wrapper {
  display: flex;
  align-items: center;
  height: 20px;
  position: relative;
  padding-left: calc(20px + 8px);
  cursor: pointer;

  &.error {
    color: #f44336;

    .checkmark {
      border-color: #f44336;
    }
  }

  .label {
    user-select: none;
    position: relative;

    &.required:after {
      content: '*';
      font-size: 22px;
      position: absolute;
      top: -50%;
      right: -8px;
      color: #f44336;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:checked ~ .checkmark {
      background-color: #534e84;
      border-color: #534e84;

      &:after {
        display: block;
      }
    }
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
    border-radius: 2px;
    border: 1px solid #a39fc6;

    &:after {
      content: '';
      position: absolute;
      display: none;
      left: 6px;
      top: 2px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      transform: rotate(45deg);
    }
  }
}
