.layout-default {
  display: flex;
  height: 100vh;

  article {
    width: 320px;
    display: flex;
    flex-shrink: 0;
  }

  section {
    flex-grow: 1;
    overflow-y: auto;
  }
}
