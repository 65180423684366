.navigation-panel {
  background: #534e84;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  header {
    padding: 8px 16px;

    img.korio-logo {
      height: 40px;
      width: auto;
    }
  }

  footer {
    background: #45416e;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    color: #e8e7f1;

    .display-name {
      flex-grow: 1;
    }

    > button > svg {
      fill: #e8e7f1;
      opacity: 0.5;
    }
  }
}

#settings-menu {
  .MuiMenu-paper {
    transform: translateY(-36px)!important;
  }
}
