.snackbar {
  max-width: 424px;
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 1.43;
  align-items: center;
  padding: 10px 16px;
  border-radius: 4px;
  color: #201f33;
  background-color: #edf7ed;

  &.success {
    background-color: #edf7ed;
  }

  &.error {
    background-color: #feeceb;
  }

  &.warning {
    background-color: #fff5d6;
  }

  &.info {
    background-color: #e9f4fe;
  }

  > header {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    font-size: 1rem;
    gap: 4px;

    span {
      font-weight: bold;
      flex-grow: 1;
    }

    .close-icon {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
    padding: 0 4px;
  }
}
