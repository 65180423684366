@keyframes spin {
  100% { transform: rotate(360deg); }
}

.loading-indicator {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparentize(#fff, 0.2);
  backdrop-filter: saturate(180%) blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

  span.MuiCircularProgress-root {
    color: #534e84;
  }
}
