#inactivity-manager {
  .inactivity-alert-container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: transparentize(#000, 0.5);
    backdrop-filter: blur(6px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;

    .inactivity-alert {
      background: #fff;
      padding: 26px;
      border-radius: 6px;
      margin-bottom: 25%;
      box-shadow: 0px 2px 6px rgba(20, 26, 24, 0.25);

      header {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 42px;

        h3 {
          margin: 0;
        }

        .ic-warning {
          color: #f5b700;
          margin-right: 4px;
        }
      }

      section {
        .bold {
          font-weight: bold;
        }
      }

      footer {
        display: flex;
        justify-content: center;
        margin-top: 42px;
      }
    }
  }
}
